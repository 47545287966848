import { Directive, inject, Input, TemplateRef } from '@angular/core';

import { SLTableEmptyStateReason } from '../models/sl-table.model';

@Directive({
	selector: '[emptyStateTemplate]',
	standalone: true
})
export class EmptyStateTemplateDirective {

	@Input() type: SLTableEmptyStateReason;

	readonly templateRef = inject(TemplateRef);
}
