import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DateOptionsConfig } from '@@shared/sl-table/models/sl-table.model';

@Component({
	selector: 'sl-column-template-date-range',
	templateUrl: './column-template-date-range.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		MatTooltipModule
	],
})
export class ColumnTemplateDateRangeComponent {
	optionsSignal$ = input<DateOptionsConfig>(null, { alias: 'options' });
	dataSignal$ = input(null, { alias: 'data' });
}
