import { CommonModule, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, contentChildren, inject, input, OnChanges, OnDestroy, OnInit, output, Signal, SimpleChanges, TemplateRef, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { IslQueryRequest, IslQueryRuleSet, Operator, SlQueryRequestBuilderWeb, SortDirection } from '@sealights/sl-query-builder';
import { omit } from 'lodash-es';
import * as LZString from 'lz-string';
import { LazyLoadEvent } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DragDropModule } from 'primeng/dragdrop';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableHeaderCheckboxToggleEvent, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { distinctUntilChanged, filter, map, Subscription, tap } from 'rxjs';

import { QueryDataStatus } from '@@shared/common/models/api.model';
import { EmptyPanelComponent } from '@@shared/empty-panel';
import { SlLocalStorageService } from '@@shared/local-storage/services/sl-local-storage.service';
import { SearchBoxComponent } from '@@shared/search-box';
import { EmptyStateTemplateDirective } from '@@shared/sl-table/directives/custom-empty-state.directive';
import { SLTableColumnDef, SLTableColumnType, SLTableConfig, SLTableEmptyStateReason, SLTableHeaderAction, SLTableSelection, TableData, TableResponse } from '@@shared/sl-table/models/sl-table.model';
import { SlTableService } from '@@shared/sl-table/services/sl-table.service';
import { TableStore } from '@@shared/sl-table/stores/table.store';
import { DEFAULT_PAGE_SIZE_OPTIONS } from '@@shared/table-filter-store/table-filter.model';

import { ColumnTemplateDateRangeComponent } from '../custom-templates/column-template-date-range/column-template-date-range.component';
import { CustomColumnTemplateComponent } from '../custom-templates/custom-column-template.component';
import { CustomHeaderTemplateComponent } from '../custom-templates/custom-header-template.component';
import { CustomRowActionsComponent } from '../custom-templates/custom-row-actions.component';
import { CustomRowExpansionComponent } from '../custom-templates/custom-row-expansion.component';
import { FilterPanelComponent } from '../filter-panel/filter-panel.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';

/**
 * A dynamic table component for displaying data with various features.
 *
 */
@Component({
	selector: 'sl-dynamic-table',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		// Modules
		TableModule,
		NgIf,
		NgClass,
		NgSwitchCase,
		NgSwitch,
		NgTemplateOutlet,
		NgSwitchDefault,
		NgFor,
		MenuModule,
		ButtonModule,
		CheckboxModule,
		MatIconModule,
		PaginatorModule,
		TooltipModule,
		DragDropModule,
		ListboxModule,
		SkeletonModule,
		VirtualScrollerModule,
		OverlayPanelModule,
		ProgressSpinnerModule,
		// Components
		TableSettingsComponent,
		FilterPanelComponent,
		SearchBoxComponent,
		EmptyStateTemplateDirective,
		ColumnTemplateDateRangeComponent,
		CommonModule,
		EmptyPanelComponent
	],
	providers: [SlTableService, TableStore, SlLocalStorageService],
	templateUrl: './dynamic-table.component.html',
	styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent<Req, Res> implements OnInit, OnChanges, OnDestroy {
	configSignal$ = input.required<SLTableConfig<Req, Res>>({ alias: 'config' });

	readonly selectionChanged = output<SLTableSelection>();
	readonly tableDataChanged = output<TableResponse<Res>>();
	readonly filterChanged = output<IslQueryRequest<Req, Res>>();

	readonly table = viewChild.required<Table>(Table);

	readonly customColumnTemplates = contentChildren(CustomColumnTemplateComponent);
	readonly customRowActionsComponent = contentChildren(CustomRowActionsComponent);
	readonly customHeaderComponent = contentChildren(CustomHeaderTemplateComponent);
	readonly customRowExpansionComponent = contentChildren(CustomRowExpansionComponent);
	readonly emptyStateTemplates = contentChildren(EmptyStateTemplateDirective);

	readonly ColumnType = SLTableColumnType;
	readonly SortDirection = SortDirection;
	readonly EmptyStateReason = SLTableEmptyStateReason;
	readonly DEFAULT_PAGE_SIZE_OPTIONS = DEFAULT_PAGE_SIZE_OPTIONS;
	QueryDataStatus = QueryDataStatus;
	customTemplatesMap: Map<string, TemplateRef<any>> = new Map();
	emptyStateTemplatesMap: Map<string, TemplateRef<any>> = new Map();
	headerTemplate: TemplateRef<any>;
	rowActionsTemplate: TemplateRef<any>;
	rowExpansionTemplate: TemplateRef<any>;
	selectedRows: Res[] = [];
	selectAll: boolean = false;
	searchTerm: string;
	headerActionsDisabledState: { [key: string]: boolean } = {};
	subscriptions: Subscription[] = [];

	readonly tableData$: Signal<TableData<Res>> = toSignal(inject(TableStore<Req, Res>).select(state => state?.tableData?.data));
	readonly columnsSignal$: Signal<SLTableColumnDef<Req, Res>[]> = toSignal(inject(TableStore<Req, Res>).select(state => state.columns));
	readonly loading$: Signal<boolean> = toSignal(inject(TableStore<Req, Res>).select(state => state.loading));
	readonly emptyStateReason$: Signal<SLTableEmptyStateReason> = toSignal(inject(TableStore<Req, Res>).select(state => state.emptyStateReason));
	readonly selectedColumns$: Signal<SLTableColumnDef<Req, Res>[]> = computed(() => (
		this.columnsSignal$().sort((a, b) => a.order - b.order).filter(col => col.isVisible && !col.isDisabled)
	));
	readonly showFilterPanel$ = computed(() => this.configSignal$().features?.filtering && this.columnsSignal$().length > 0);
	readonly showSearchBox$ = computed(() => this.configSignal$().features?.searchBox);
	readonly showTableSettings$ = computed(() => !this.configSignal$().features?.hideSettings && this.columnsSignal$().length > 0);
	readonly activeFilterSignal$ = computed(() => this.configSignal$().dataSourceConfig?.defaultQuery?.ruleSet?.ruleSets.concat(this.configSignal$().dataSourceConfig?.defaultQuery?.ruleSet));
	#isFirstRun: boolean = true;

	readonly #cdr = inject(ChangeDetectorRef);
	readonly #tableStore = inject(TableStore<Req, Res>);
	readonly #localStorage = inject(SlLocalStorageService);
	readonly #router = inject(Router);
	readonly #activatedRoute = inject(ActivatedRoute);

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.configSignal$) {
			if (this.#isFirstRun) {
				this.refreshTable(this.configSignal$().updateColumnsOnChange);
			} else {
				this.#updateUrlParams();
			}
		}
	}

	ngOnInit(): void {
		if (!this.configSignal$()) {
			console.error('no config');
			return;
		}
		this.#initializeStateFromUrl();
		this.#listenToParamsChange();
		this.#setCustomTemplates();
		this.#initSearchTerm();
		this.#initTableDataChangedListener();
	}

	ngOnDestroy(): void {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}

		this.#handleRemoveFilterStateOnDestroy();
	}

	/**
	* Handles page change events from the paginator.
	*
	* This method updates the pagination parameters (limit and offset) in the table's query
	* and then triggers an update of the URL parameters to reflect the new pagination state.
	*
	* @param paginatorState - The event object containing details about the pagination state, including the current page's row limit and the index of the first row in the page.
	*/
	onPageChange(paginatorState: PaginatorState): void {
		const newLimit = paginatorState.rows;
		const newOffset = paginatorState.first;
		this.configSignal$().dataSourceConfig.defaultQuery.offset = newOffset;
		this.configSignal$().dataSourceConfig.defaultQuery.limit = newLimit;
		this.#updateUrlParams();
	}

	/**
	* Handles changes in the search term and updates the query accordingly.
	*
	* @param searchTerm The search term.
	*/
	onSearchChange(searchTerm: string): void {
		if (this.configSignal$().features.searchBox.global) {
			this.configSignal$().dataSourceConfig.defaultQuery.globalSearch = searchTerm || null;
		} else if (this.configSignal$().features.searchBox.fieldToSearch) {
			this.configSignal$().dataSourceConfig.defaultQuery.ruleSet.rules = this.configSignal$().dataSourceConfig.defaultQuery.ruleSet.rules.filter(rule => rule.field !== this.configSignal$().features.searchBox.fieldToSearch);
			this.configSignal$().dataSourceConfig.defaultQuery.ruleSet.rules.push({ field: this.configSignal$().features.searchBox.fieldToSearch, operator: Operator.Contains, value: searchTerm });
		}

		this.#updateUrlParams();
	}

	/**
	* Handles changes in the applied filters and updates the query accordingly.
	*
	* @param ruleSet The filter change event, containing the new rule set.
	*/
	onFilterChange(ruleSet: IslQueryRuleSet<Req, Res>): void {
		this.configSignal$().dataSourceConfig.defaultQuery.ruleSet.ruleSets = ruleSet.ruleSets;
		this.filterChanged.emit(this.configSignal$().dataSourceConfig.defaultQuery);
		this.#updateUrlParams(true);
	}

	/**
	* Handles sorting events and updates the query to reflect the new sort state.
	*
	* @param event The lazy load event object containing sorting information such as sorted field and sort order
	*/
	onSort(event: LazyLoadEvent): void {
		if (this.#isFirstRun) {
			return;
		}
		this.configSignal$().dataSourceConfig.defaultQuery.sortBy = event.sortField ? [event.sortField as keyof Res] : [];
		this.configSignal$().dataSourceConfig.defaultQuery.sortDirection = [event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC];
		this.#updateUrlParams();
	}

	/**
	* Refreshes the table data. Optionally, updates the column configuration.
	*
	* @param isFirstRun Indicates if this is the initial run to set up the table.
	*/
	refreshTable(forceUpdateColumns: boolean): void {
		this.#tableStore.fetchData({ config: this.configSignal$(), forceUpdateColumns });
		this.#cdr.detectChanges();
	}

	/**
	* Executes the action associated with a table header actions.
	*
	* @param tableHeaderAction The action configuration to execute.
	* @param $event The actual Click Event associated with the action.
	*/
	onTableHeaderActionExecute(tableHeaderAction: SLTableHeaderAction, $event: Event): void {
		$event.stopPropagation();
		$event.preventDefault();
		if (tableHeaderAction.disabled) {
			return;
		}
		tableHeaderAction.action($event);
	}

	/**
	* Emits an event when the selection of rows in the table changes.
	*
	* @param selectedRows The array of selected rows.
	*/
	onSelectionChange(selectedRows: Res[]): void {
		setTimeout(() => {
			const selectionChangeEvent: SLTableSelection = {
				all: this.selectAll,
				selection: selectedRows,
				actions: this.configSignal$().features.headerActions
			};
			this.selectionChanged.emit(selectionChangeEvent);
		});
	}

	/**
	* update the selectAll property and triggers the onSelectionChange method.
	*
	* @param $event TableHeaderCheckboxToggleEvent.
	*/
	selectAllChange($event: TableHeaderCheckboxToggleEvent): void {
		this.selectAll = $event.checked;
		this.#cdr.detectChanges();
	}

	/**
	* Manages the order and visibility of columns based on user interaction.
	*
	* @param updatedColumns The updated array of column definitions.
	*/
	manageColumnOrderAndVisibility(updatedColumns: SLTableColumnDef<Req, Res>[]): void {
		if (!updatedColumns) {
			return;
		}
		this.#tableStore.setColumns(updatedColumns);
		this.#localStorage.saveState(this.configSignal$().id, updatedColumns.map(col => omit(col, 'metaData')));
		this.#cdr.detectChanges();
	}

	#initializeStateFromUrl(): void {
		const params = this.#activatedRoute.snapshot.queryParams;
		const queryFromUrl = params ? params[this.#constructFilterQueryParmName()] as string : '';
		if (queryFromUrl) {
			const result = this.#loadQueryFromUrl(queryFromUrl);
			if (result.query) {
				this.configSignal$().dataSourceConfig.defaultQuery = result.query;
			}
		}
		this.#isFirstRun = false;
	}

	/**
	* Initializes and sets custom templates for columns, row actions, row expansion, and empty state.
	*/
	#setCustomTemplates(): void {
		setTimeout(() => {
			// Custom Column Templates
			if (this.customColumnTemplates()?.length > 0) {
				this.customColumnTemplates().forEach(comp => {
					this.customTemplatesMap.set(comp.column, comp.templateRef());
				});
			}

			if (this.customHeaderComponent()?.length > 0 && this.customHeaderComponent()?.[0]?.templateRef()) {
				this.headerTemplate = this.customHeaderComponent()[0].templateRef();
			}

			// Custom Row Actions Template
			if (this.customRowActionsComponent()?.length > 0 && this.customRowActionsComponent()?.[0]?.templateRef()) {
				this.rowActionsTemplate = this.customRowActionsComponent()[0].templateRef();
			}

			// Custom Row Expansion Template
			if (this.customRowExpansionComponent()?.length > 0 && this.customRowExpansionComponent()?.[0]?.templateRef()) {
				this.rowExpansionTemplate = this.customRowExpansionComponent()[0].templateRef();
			}

			// Custom Empty Table Template
			if (this.emptyStateTemplates()?.length > 0) {
				this.emptyStateTemplates().forEach(x => {
					this.emptyStateTemplatesMap.set(x.type, x.templateRef);
				});
			}
			this.#cdr.detectChanges();
		});
	}

	/**
	* Updates the URL query parameters based on the current state of the query.
	*/
	#updateUrlParams(isFilterChange: boolean = false): void {
		const queryObject = {
			defaultQuery: this.configSignal$().dataSourceConfig.defaultQuery,
			isFilterChange: isFilterChange ? 'true' : 'false'
		};
		const compressedQuery = LZString.compressToEncodedURIComponent(JSON.stringify(queryObject));

		setTimeout(() => {
			void this.#router.navigate([], {
				relativeTo: this.#activatedRoute,
				queryParams: {
					[this.#constructFilterQueryParmName()]: compressedQuery,
				},
				queryParamsHandling: 'merge'
			});
		});
	}


	/**
	* Listens to url changes. Loads the query from the URL and refreshes the table.
	*/
	#listenToParamsChange(): void {
		this.subscriptions.push(
			this.#activatedRoute.queryParams.pipe(
				// Extract only the relevant parameter to watch for changes
				map(params => params[this.#constructFilterQueryParmName()] as string),
				distinctUntilChanged(),
				filter((queryFromUrl: string) => !!queryFromUrl),
				tap((queryFromUrl: string) => {
					const result = this.#loadQueryFromUrl(queryFromUrl);
					if (result.query) {
						this.configSignal$().dataSourceConfig.defaultQuery = result.query;
					}
					setTimeout(() => {
						const forceUpdateColumns = (this.#isFirstRun || this.configSignal$().updateColumnsOnChange) && !result.isFilterChange;
						this.refreshTable(forceUpdateColumns);
					});
				})
			).subscribe()
		);
	}

	/**
	* Decodes and loads the query from the compressed URL parameter.
	*
	* @param value The compressed query string from the URL.
	*/
	#loadQueryFromUrl(value: string): { query: IslQueryRequest<Req, Res>; isFilterChange: boolean } {
		const decompressedData = JSON.parse(LZString.decompressFromEncodedURIComponent(value)) as { defaultQuery: IslQueryRequest<Req, Res>; isFilterChange: string };
		const query = decompressedData.defaultQuery;
		const isFilterChange = decompressedData.isFilterChange === 'true';

		return { query, isFilterChange };
	}


	#initSearchTerm(): string {
		if (!this.configSignal$().features?.searchBox) {
			return '';
		}
		if (this.configSignal$().features.searchBox.global) {
			this.searchTerm = this.configSignal$().dataSourceConfig.defaultQuery.globalSearch;
		} else if (this.configSignal$().features.searchBox.fieldToSearch) {
			this.searchTerm = SlQueryRequestBuilderWeb
				.build<Req, Res>(this.configSignal$().dataSourceConfig.defaultQuery)
				.findRules(this.configSignal$().features.searchBox.fieldToSearch)
				.find(rule => rule.operator === Operator.Contains)?.value as string;
		}
	}
	/**
	 * Handle table data changed
	 */
	#initTableDataChangedListener(): void {
		this.#tableStore.select(state => state?.tableData)
			.pipe(
				filter(data => !!data)
			)
			.subscribe(data => {
				this.#initHeaderActionsDisabledState();
				this.tableDataChanged.emit(data);
			});
	}

	/**
	 * Checks if header actions should be disabled
	 */
	#initHeaderActionsDisabledState(): void {
		this.headerActionsDisabledState = this.configSignal$().features?.headerActions?.reduce(
			(state, value) => ({
				...state,
				[value.id]: value.disableFunc ?
					(value.disableFunc(this.tableData$()) || value.disabled) :
					!!value.disabled
			}),
			{});
	}

	#constructFilterQueryParmName(): string {
		return `${this.configSignal$().id}_q`;
	}

	#handleRemoveFilterStateOnDestroy(): void {
		if (this.configSignal$()?.features?.removeFilterStateOnDestroy) {
			void this.#router.navigate([], {
				relativeTo: this.#activatedRoute,
				queryParams: {
					[this.#constructFilterQueryParmName()]: null
				},
				queryParamsHandling: 'merge'
			});
		}
	}
}
